import "./App.css";
import { useMemo } from "react";

import Minter from "./Minter";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";


const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getMathWallet() ],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  
  return (
    <div>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button" >
          <img src="/icons/close.svg" alt="" onClick={toggleMenu}/>
        </div>
        <ul>
          <li>
            <img className="mobile-nav-logo" src="/img/logo.png" alt="" />
          </li>
          <li>
            <a href="/#link1" onClick={toggleMenu}>
              Link 1
            </a>
          </li>
          <li>
            <a href="/#link2" onClick={toggleMenu}>
              Link 2
            </a>
          </li>
          <li>
            <a href="/#link3" onClick={toggleMenu}>
              Link 3
            </a>
          </li>
          <li>
            <a href="/#link4" onClick={toggleMenu}>
              Link 4
            </a>
          </li>
          <li>
            <div className="social-icons">
              <img className="nav-social" src="/icons/twitter.svg" alt="" />
              <img className="nav-social" src="/icons/discord.svg" alt="" />
            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>
      <nav>
        <div className="nav-container">
          {/* <img className="nav-logo" src="/img/logo.png" alt="" /> */}
          <a className="hide-800" href="/#link1">
            Home
          </a>
          <a className="hide-800" href="/#link2">
            Benefits & Utilities
          </a>
          <a className="hide-800" href="/#link3">
            Roadmap
          </a>
          <a className="hide-800" href="/#link4">
            The Team
          </a>
          <div className="social-icons hide-800">
            <a href= "https://twitter.com/VigilanteApes">
            <img className="nav-social" src="/icons/twitter.svg" alt="" />
            </a>
            <a href="https://discord.gg/kAXbeFmrdn">
            <img className="nav-social" src="/icons/discord.svg" alt="" />
            </a>
          </div>
        </div>
      </nav>
      <div className="content-wrapper">
          <header className="card" id="link1">
            <div style={{ padding: "0 24px 0 24px 0" }}>
              <h3 className="text-secondary-color">Welcome To</h3>
              <h1 className="pb-3">Vigilante Apes</h1>
              <img className="teampic" src="/img/Logo2.gif" alt="" />
              <p className="text-secondary-color">
              Vigilante Apes is a collection of 2,222 unique 2D hand drawn Apes united together to fight crime on the Solana Blockchain.
              </p>
              <p className="text-secondary-color">
              Each ape is unique and exclusively based on a hundred and forty-six traits different.
              The objective of this is to build the strongest community and project around NFTs.
              </p>
            </div>
            <div>


            <a href="https://discord.gg/kAXbeFmrdn">
            <img className="mintbuttong" src="/img/mint.png" alt="" />
            </a>


             {/*  <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                      
                        <Minter
                          candyMachineId={candyMachineId}
                          
                          connection={connection}
                          startDate={startDateSeed}
                          txTimeout={txTimeout}
                          rpcHost={rpcHost}
                        />
                      
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ThemeProvider> */}
            </div>
          </header>

          <div id="link2" className="container">
          Entering into the Vigilante Apes community means joining a family full of investors and nft enthusiasts who believe in the future of Cryptocurrencies and the blockchain technology.
          </div>

          <div id="link3" className="container card">
            <h1 className="pb-3">JOIN THE COMMUNITY</h1>
            <img className="teampic" src="/img/2.gif" alt="" />
            <img className="teampic" src="/img/9.gif" alt="" />
            <img className="teampic" src="/img/4.gif" alt="" />
            <img className="teampic" src="/img/5.gif" alt="" />
          </div>

          <div id="link4" className="container faq">
            <h1 style={{ padding: "0 0 24px 0" }}>FAQ</h1>
            <div>
              <h4>Benefits & Utilities</h4>
              <p>
              When you buy into the Vigilante Apes community, you’re not just buying a simple NFT, you are getting
            access to a community where benefits and utilities will increase with the time.
              </p>

              <hr />
            </div>

            <div>
              <h4>Roadmap</h4></div>
              <div>
              <h5>First & Foremost - 20%</h5>
              <p>
              To us, the community comes first. The main goal is to make sure your investment and our mint are as successful as possible, to achieve all of our goals. We will also constantly work to release the best value and quality possible. We will announce our drop release date once we are satisfied with the project’s quality and technical parts.
              </p>
              <h5>Launch of our Merch - 40%</h5>
              <p>
              We will release our first merch collection of Vigilante Apes that will be available only for the holders. 
              </p>
              <h5>Funding Time, Airdrops, Staking, Whitelist & Breeding Unlock - 60%</h5>
              <p>
                We are committed to support, invest, reward, and collaborate with the best projects in the space and project ideas of our community members. Airdrops & Whitelist access of any future project for holders. Staking & breeding will be incorperated into our future projects to insure holders make a return on their investment.
              </p>

              <h5>Locking Up Funds For The Community - 80%</h5>
              <p>
              We will deposit funds in a community wallet and will let the community vote on what will happen to the funds.
              </p>
              
              <h5>Vigilante Apes For The World - 100%</h5>
              <p>
              We want to show you that you made the best choice to join Vigilante Apes and make sure that your investment is profitable. To do so we are planning to do massive marketing (well known celebrities, Ads in the biggest cities in the world ...)
              </p>
              
              <hr />
            </div>

            <div>
              <h3>The Team</h3>
              
              </div>

              <div> 
              <h4 className="teamname">Yorael</h4>
              <h4 className="teamname">Lerej</h4>
              <h4 className="teamname">Learoy</h4>
              <h4 className="teamname">Pump Snooki</h4>
              </div>

              <div className="descript-format">
              <h5 className="descript">Co-Founder</h5>
              <h5 className="descript">Co-Founder</h5>
              <h5 className="descript">Developer</h5>
              <h5 className="descript">Community Manager</h5>
              </div>
              
              <div className="descript-format">
              <h5 className="descript2">Designer</h5>
              <h5 className="descript2">Designer</h5>
              <h5 className="descript2">Investor</h5>
              <h5 className="descript2">Investor </h5>
              </div>

              <div>
              <img className="teampic" src="/img/Yoreal.gif" alt="" />          
              <img className="teampic" src="/img/Glaze.gif" alt="" />
              <img className="teampic" src="/img/Learoy.gif" alt="" />
              <img className="teampic" src="/img/Pump Snooki.gif" alt="" />  
              <hr />
            </div>
            
          </div>
      </div>
    </div>
  );
};

export default App;
